import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SecondPage = ({ data }) => (
  <Layout>
    <Seo title="KCUA GEIKEN Open Campas 2022" />
    {data.allMicrocmsBlog.edges.map(({ node }) => (
      <>
        <div className="topbox">
          <divx
            dangerouslySetInnerHTML={{
              __html: `${node.title}`,
            }}
          />
          <br />
          <hr />
          <divx
            dangerouslySetInnerHTML={{
              __html: `${node.description}`,
            }}
          />
        </div>
        <div className="rightbox">
          <divx
            dangerouslySetInnerHTML={{
              __html: `${node.senko_title}`,
            }}
          />
          <br />
          <hr />
          <divx
            dangerouslySetInnerHTML={{
              __html: `${node.senko}`,
            }}
          />
        </div>
        <div className="leftbox">
          <divx
            dangerouslySetInnerHTML={{
              __html: `${node.opensemi_title}`,
            }}
          />
          <br />
          <hr />
          <divx
            dangerouslySetInnerHTML={{
              __html: `${node.opensemi}`,
            }}
          />
        </div>
        <div className="live">
          <a href="https://youtu.be/xaGI1SFZ1rY" class="btn btn-malformation">
            <divx
              dangerouslySetInnerHTML={{
                __html: `${node.live}`,
              }}
            />
          </a>
        </div>
      </>
    ))}
  </Layout>
)

export default SecondPage

export const query = graphql`
  query {
    allMicrocmsBlog {
      edges {
        node {
          blogId
          title
          description
          senko_title
          senko
          opensemi_title
          opensemi
          live
        }
      }
    }
  }
`
